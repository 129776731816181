<template>
  <section class="w-screen h-screen absolute overflow-hidden text-left ">
    <div class="base"
    :class="{close : open}"
    >
      <div class="menu"
        @click="openMenu"
      >
        <div class="icon">
          <div class="bar"></div>
        </div>
      </div>
      <div class="icons">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar" class="svg-inline--fa fa-calendar-o fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm436-44v-36c0-26.5-21.5-48-48-48h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v36c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12z"></path></svg>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="tachometer-alt" class="svg-inline--fa fa-tachometer fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm0 64c14.71 0 26.58 10.13 30.32 23.65-1.11 2.26-2.64 4.23-3.45 6.67l-9.22 27.67c-5.13 3.49-10.97 6.01-17.64 6.01-17.67 0-32-14.33-32-32S270.33 96 288 96zM96 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm48-160c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm246.77-72.41l-61.33 184C343.13 347.33 352 364.54 352 384c0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-33.94 26.5-61.43 59.9-63.59l61.34-184.01c4.17-12.56 17.73-19.45 30.36-15.17 12.57 4.19 19.35 17.79 15.17 30.36zm14.66 57.2l15.52-46.55c3.47-1.29 7.13-2.23 11.05-2.23 17.67 0 32 14.33 32 32s-14.33 32-32 32c-11.38-.01-20.89-6.28-26.57-15.22zM480 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"></path></svg>
      </div>
      <div class="section">
        <div class="cover1">
          <div class="cover2">
            <a class="content" href="#calender"></a>
          </div>
        </div>
      </div>
      <a class="section-static top" href="#profile"></a>
      <a class="section-static bottom" href="#dashboard"></a>
    </div>
  </section>

</template>

<script>




export default {
  name: "HamburgerMenuNr5",


  data() {

    return {

      open: false,

    }


  },

  methods: {

    openMenu() {

      this.open = !this.open


    }
  },

  mounted() {


  }

}
</script>


<style scoped lang="scss">
$m: #21264B;
$iconmargin: 35px;
//Took about 100 more lines than I expected
section {
  background-color: $m;
  overflow-x:hidden;
  .base {
    z-index: 90;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #FFFFFF;
    width: 98px;
    height: 98px;
    transition: all 1s cubic-bezier(0.50, -0.75, 0.05, 1);
    border-bottom-right-radius: 100%;
    //overflow:hidden;
    .menu {
      z-index: 100;
      background-color: #FFFFFF;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100px;
      height: 100px;
      border-bottom-right-radius: 200px;
      cursor: pointer;
      transition: all 1s cubic-bezier(0.50, -0.75, 0.05, 1), background-color 1s ease;
      .icon {
        position: absolute;
        width: 25px;
        height: 25px;
        top: 50%;
        left: 50%;
        transform: translate(-100%, -100%);
        &:before,
        &:after {
          content: '';
          transform: rotate(0deg);
          transition: top 0.5s ease 0.5s, transform 0.5s ease, background-color 0.75s ease 0.25s;
        }
        .bar,
        &:before,
        &:after {
          position: absolute;
          height: 5px;
          left: 0px;
          right: 0px;
          border-radius: 5px;
          background-color: $m;
        }
        .bar {
          transition: opacity 0s linear 0.5s, background-color 0.75s ease 0.25s;
          opacity: 1;
          top: 10px;
        }
        &:before {
          top: 0px;
        }
        &:after {
          top: initial;
          top: 20px;
        }
      }
    }
    .icons {
      z-index: 98;
      position: absolute;
      top:0px;
      left:0px;
      >* {
        position: absolute;
        font-size:40px;
        color:$m;
        transition: 0.3s cubic-bezier(0.50, -0.25, 0.05, 1);
      }
      .fa-user {
        width: 35px;
        top:$iconmargin;
        left:0px;
        transition-delay:0.2s;
      }
      .fa-calendar-o {
        width: 35px;
        top:0px;
        left:0px;
        color:#fff;
        transition-delay:0.1s;
      }
      .fa-tachometer {
        width: 45px;
        top:0px;
        left:$iconmargin;
      }
    }
    .section { //Overview of the arc: https://codepen.io/k-ya/pen/qrgZpx
      z-index: 96;
      position: absolute;
      top:0px;
      left:0px;
      height:0px;
      width:0px;
      transform-origin: 100% 100%;
      transform:rotate(135deg);
      .cover1 {
        transform-origin: 100% 100%;
        &,
        .cover2,
        .cover2 .content {
          position: absolute;
          width: 600px;
          height: 600px;
        }
        &,
        .cover2 {
          top: 50%;
          left: 50%;
          transform: translate(-100%, -100%) rotate(4deg);
          overflow: hidden;
          pointer-events:none;
          transition:transform 0.5s ease-in;
        }
        .cover2 {
          transform: translate(50%, -50%) rotate(-8deg);
          transform-origin: 0% 100%;
          .content {
            width: 150px;
            height: 150px;
            border-radius: 100%;
            background-color: #EE1B59;
            top: 100%;
            left: 0%;
            transform: translate(-50%, -50%);
            transition: background-color 0s, width 1.5s cubic-bezier(0.50, -0.5, 0.05, 1) 0s, height 1.5s cubic-bezier(0.50, -0.5, 0.05, 1) 0s;
            pointer-events: auto;
          }
        }
      }
    }
    .section-static {
      z-index: 94;
      width:100px;
      height:100px;
      position: absolute;
      top:0px;
      left:0px;
      transform-origin:0% 0%;
      transition: width 1s cubic-bezier(0.50, -0.75, 0.05, 1), height 1s cubic-bezier(0.50, -0.75, 0.05, 1);
      &:hover {
        background-color:#EAEAEA;
      }
      &.top {
        transform:rotate(-45deg);
        border-bottom-right-radius: 400px;
      }
      &.bottom {
        transform:rotate(45deg);
        border-bottom-right-radius:400px;
      }
    }
    &.close {
      width: 300px;
      height: 300px;
      transition: all 1.25s cubic-bezier(0.50, 0, 0.05, 1.75);
      .menu {
        width: 150px;
        height: 150px;
        transition: all 1s cubic-bezier(0.50, 0, 0.05, 1.75), background-color 1s ease;
        background-color: $m;
        .icon {
          .bar,
          &:before,
          &:after {
            background-color: #FFFFFF;
          }
          .bar {
            opacity: 0;
          }
          &:before,
          &:after {
            transition: top 0.5s linear, transform 0.5s ease 0.5s, background-color 0.75s ease 0.25s;
          }
          &:before {
            top: 10px;
            transform: rotate(-45deg);
          }
          &:after {
            top: 10px;
            transform: rotate(45deg);
          }
        }
      }
      .icons {
        position: absolute;
        top:0px;
        left:0px;
        >* {
          position: absolute;
          font-size:40px;
          color:$m;
          transition: 0.3s cubic-bezier(0.50, 0, 0.05, 1.75) 0.7s;
          pointer-events:none;
        }
        .fa-user {
          top:$iconmargin;
          left:200px;
        }
        .fa-calendar-o {
          top:141px;
          left:141px;
          color:#fff;
          transition-delay:0.65s;
        }
        .fa-tachometer {
          top:200px;
          left:$iconmargin;
          transition-delay:0.8s;
        }
      }
      .section {
        .cover1 {
          transform-origin: 100% 100%;
          &,
          .cover2,
          .cover2 .content {
            position: absolute;
            width: 600px;
            height: 600px;
          }
          &,
          .cover2 {
            top: 50%;
            left: 50%;
            transform: translate(-100%, -100%) rotate(16deg);
            overflow: hidden;
            transition:transform 0.5s ease-in 0.5s;
          }
          .cover2 {
            transform: translate(50%, -50%) rotate(-32deg);
            transform-origin: 0% 100%;
            .content {
              border-radius: 100%;
              background-color: #EE1B59;
              top: 100%;
              left: 0%;
              transform: translate(-50%, -50%);
              transition: background-color 0s, width 1.1s cubic-bezier(0.50, 0, 0.05, 1.75) 0.25s, height 1.1s cubic-bezier(0.50, 0, 0.05, 2) 0.25s;
              &:hover {
                background-color:#DD1350;
              }
            }
          }
        }
      }
      .section-static {
        width:300px;
        height:300px;
        transition: width 1.25s cubic-bezier(0.50, 0, 0.05, 2), height 1.25s cubic-bezier(0.50, 0, 0.05, 2);
      }
    }
  }
}


</style>